const i18next = require('i18next');
const moment = require('moment');
const XHR = require('i18next-xhr-backend');
const resBundle = require('./assets/i18n/index.js');

const options = {
    interpolation : {
        // React already does escaping
        escapeValue : false,
    },
    fallbackLng : ['en'],
    languages   : ['fr', 'en'],
    preload     : ['fr', 'en'],
    load        : 'all',
    debug       : false,
    ns          : ['static', 'menu', 'cms', 'common', 'login', 'addresses', 'account', 'resetpass'],
    react       : {
        wait : true,
    },
    resource : resBundle,
};

const i18nInstance = i18next;

// for browser use xhr backend to load translations on client side
if (process.browser) {
    i18nInstance
        .use(XHR);
}

if (!i18nInstance.isInitialized) i18nInstance.init(options);

if (module.hot) {
    module.hot.accept('./assets/i18n/index.js', () => {
        const res = require('./assets/i18n/index.js');
        Object
            .keys(res)
            .forEach((lang) => {
                Object
                    .keys(res[lang])
                    .forEach((namespace) => {
                        i18nInstance.addResourceBundle(lang, namespace, res[lang][namespace], true, true);
                    });
            });
        i18nInstance.emit('loaded');
    });
}

// i18nInstance.on()


// a simple helper to getInitialProps passed on loaded i18n data
const getInitialProps =  (req, namespaces) => {
    let url = req.url;
    if (url.indexOf('?') > -1) {
        url = url.substr(0, url.indexOf('?'));
    }
    if ((url && url.indexOf('.') > -1) || (url && url.indexOf('admin') > -1)) {
        req.res.end();
    }
    if (!namespaces) namespaces = i18nInstance.options.defaultNS;
    if (typeof namespaces === 'string') namespaces = [namespaces];
    namespaces = namespaces.concat(['common']);

    req.i18n.toJSON = () => null; // do not serialize i18next instance and send to client

    /* On récupere la liste des langues de Aquila */
    /**
     * TODO: Remplir le tableau dynamiquemant
     */
    let statusCode = 200;
    let message = 'OK';
    const langs = [{ code: 'en', defaultLanguage: false }, { code: 'fr', defaultLanguage: true }];
    let lang = langs.find(element => element.defaultLanguage === true).code;
    const urlParams = req.path.split('/');
    if (req.path.length === 1) {
    } else if (req.path.length > 3 && ((urlParams[1]).match(/[a-z]{2}/)) || ((req.path[0] === '/') && (urlParams[1]).match(/[a-z]{2}/) && req.path.length === 3)) {
        const pathLang = urlParams[1];
        if (langs.find(element => element.code === pathLang)) {
            lang = pathLang;
        } else {
            statusCode = 404;
            message = 'Language not found';
        }
    }

    req.i18n.language = lang;
    moment.locale(lang);

    const initialI18nStore = {};
    const l = req.i18n.language;
    initialI18nStore[l] = {};
    namespaces.forEach((ns) => {
        initialI18nStore[l][ns] = (req.i18n.services.resourceStore.data[l] || {})[ns] || {};
    });

    i18nInstance.on('languageChanged', (lng) => {
        moment.locale(lng);
        console.log('i18n', lng);
    });

    return {
        i18n            : req.i18n, // use the instance on req - fixed language on request (avoid issues in race conditions with lngs of different users)
        initialI18nStore,
        initialLanguage : req.i18n.language,
        prefix          : req.i18n.language !== 'fr' ? `/${req.i18n.language}` : '',
    };
};

module.exports = {
    getInitialProps,
    i18nInstance,
    I18n : i18next.default,
    ns   : options.ns
};

